<template>
  <el-dialog
    v-if="dialogBool"
    title="Түүх харах"
    :visible.sync="dialogBool"
    width="60%"
    :close-on-click-modal="false"
    @close="close"
    @closed="close"
    center
  >
    <span>
      <el-table :data="logsData" style="width: 85%">
        <el-table-column
          prop="old.bairname"
          label="Хуучин хаяг"
        ></el-table-column>
        <el-table-column
          prop="new.bairname"
          label="Шинэ хаяг"
        ></el-table-column>
        <el-table-column prop="created_at" label="Огноо"></el-table-column>
        <el-table-column prop="action" label="Үйлдэл"></el-table-column>
        <el-table-column prop="user" label="Хэрэглэгч"></el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right"
        @size-change="logSizeChangeHandler"
        layout="total, sizes, prev, pager, next"
        :total="logPageCount"
        :page-size="logSize"
        :page-sizes="[10, 20, 30, 50, 100]"
        @current-change="logSetPage"
      >
      </el-pagination>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeFunc()" type="danger">Хаах</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    logPageCount: Number,
    dialogBool: Boolean,
    logSize: Number,
    logSizeChangeHandler: Function,
    logSetPage: Function,
    logsData: Array,
    close: Function
  },
  methods: {
    closeFunc() {
      this.close();
    }
  }
};
</script>
