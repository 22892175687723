<template>
  <div class="home">
    <el-dialog
      :title="type === true ? 'Хаяг нэмэх' : 'Хаяг засварлах'"
      :visible.sync="showAddressDialog"
      :close-on-click-modal="false"
      width="90%"
      @open="onOpenDialog()"
      @close="onCloseDialog()"
    >
      <el-form v-model="oldAddressData" size="small">
        <el-row>
          <el-col :span="8">
            <el-form-item label="Хот:" label-width="120px">
              <el-select v-model="oldAddressData.city" @change="changeCity">
                <el-option
                  v-for="(city, indexCity) in cities"
                  :key="indexCity"
                  :label="city.value"
                  :value="city.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Дүүрэг:" label-width="120px">
              <el-select
                v-model="oldAddressData.district"
                placeholder="Дүүргээ сонгоно уу?"
                @change="changeDisrict1"
              >
                <el-option
                  v-for="(district, indexDistrict) in districts"
                  :key="indexDistrict"
                  :label="district.value"
                  :value="district.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Хороо:" label-width="120px">
              <el-select
                v-model="oldAddressData.horoo"
                placeholder="Хороогоо сонгоно уу?"
              >
                <el-option
                  v-for="h in horoos"
                  :key="h.name"
                  :label="h.name"
                  :value="h.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Хаяг:" label-width="120px">
              <el-input v-model="oldAddressData.bairname"></el-input>
            </el-form-item>
            <el-form-item label="Албан бус хаяг:" label-width="120px">
              <el-input v-model="oldAddressData.bairnote"></el-input>
            </el-form-item>
            <el-form-item label="Х/Бүс:" label-width="120px">
              <el-input v-model="oldAddressData.podcode"></el-input>
            </el-form-item>
            <el-form-item label="TOKTOK Х/Бүс:" label-width="120px">
              <el-input v-model="oldAddressData.podtoktok"></el-input>
            </el-form-item>
            <el-form-item label="Төлөв:" label-width="120px">
              <el-switch
                v-model="oldAddressData.is_active"
                style="display: block; text-align: center"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="Идэвхтэй"
                inactive-text="Идэвхгүй"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="О/ хаяг:" label-width="120px">
              <el-table :data="this.nearbyAddress" max-height="250">
                <el-table-column
                  prop="full_address"
                  label="Хаяг"
                ></el-table-column>
                <el-table-column
                  prop="bairname"
                  label="Албан бус хаяг"
                ></el-table-column>
                <el-table-column label="Үйлдэл">
                  <el-button
                    slot-scope="scope"
                    size="mini"
                    type="info"
                    @click="onClickNearbyAddress(scope.row)"
                    >Засах</el-button
                  ></el-table-column
                >
              </el-table>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="showAddressDialog">
            <el-form-item label="Пин дроп:" label-width="120px">
              <gmap-map
                :center="center"
                :zoom="14"
                :options="options"
                :draggable="false"
                style="width:90vh;  height: 50vh;"
              >
                <gmap-marker
                  :position="points"
                  :title="points.lat + ' ' + points.lng"
                  :draggable="true"
                  @dragend="updatePath($event)"
                >
                </gmap-marker>
              </gmap-map>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCloseDialog">Хаах</el-button>
        <el-button v-if="type === false" type="primary" @click="update"
          >Засах</el-button
        >
        <el-button v-else-if="type === true" type="primary" @click="create"
          >Нэмэх</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from "../helpers/services.js";
export default {
  props: {
    addressData: Object,
    showAddressDialog: Boolean,
    type: Boolean,
    closeFunction: Function,
    cities: Array
  },
  created() {
    // this.getDistricts();
  },
  data() {
    return {
      districts: [],
      user: "",
      district: "",
      horoo: "",
      apart: {},
      newApart: {},
      horoos: [],
      nearbyAddress: [],
      tableSearch: "",
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 2,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      oldAddressData: {},
      center: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      },
      points: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      }
    };
  },
  methods: {
    changeCity(city) {
      this.getDistricts(city);
    },
    getDistricts(city) {
      services.getDistricts(city).then(response => {
        this.districts = response.data.data;
      });
    },
    onOpenDialog() {
      if (this.type === false) {
        this.oldAddressData = JSON.parse(JSON.stringify(this.addressData));
        this.points.lat = parseFloat(this.oldAddressData.lat);
        this.points.lng = parseFloat(this.oldAddressData.lon);
        this.center.lat = parseFloat(this.oldAddressData.lat);
        this.center.lng = parseFloat(this.oldAddressData.lon);
        this.changeDisrict1();
        this.$forceUpdate();
      } else {
        this.oldAddressData = {};
        this.points.lat = 47.9174;
        this.points.lng = 106.9137;
      }
    },
    create() {
      this.oldAddressData.lat = this.points.lat;
      this.oldAddressData.lon = this.points.lng;
      this.oldAddressData.latitude = this.points.lat;
      this.oldAddressData.longitude = this.points.lng;
      services.create(this.oldAddressData).then(response => {
        if (response.status === "success") {
          this.$notify({
            title: "Амжилттай.",
            message: response.message,
            type: "success"
          });
        } else {
          this.$notify({
            title: "Анхааруулга.",
            message: "Хаягийг нэмэх үйлдэл амжилтгүй боллоо.",
            type: "warning"
          });
        }
      });
    },
    update() {
      this.oldAddressData.lat = parseFloat(this.points.lat);
      this.oldAddressData.lon = parseFloat(this.points.lng);
      this.oldAddressData.latitude = parseFloat(this.points.lat);
      this.oldAddressData.longitude = parseFloat(this.points.lng);
      this.oldAddressData.is_active = parseInt(this.oldAddressData.is_active);
      this.oldAddressData.id = this.oldAddressData._id
        ? this.oldAddressData._id
        : this.oldAddressData.id;
      services
        .updateAddress(this.oldAddressData.id, this.oldAddressData)
        .then(data => {
          if (data.data.status === "success") {
            if (
              this.addressData.lat !== this.points.lat ||
              this.addressData.lon !== this.points.lng
            ) {
              const payload = {
                lat: this.points.lat,
                lon: this.points.lng
              };
              services
                .updateAddresLoc(this.oldAddressData.id, payload)
                .then(res => {
                  if (res.data.data.message === "Амжилттай засагдлаа...") {
                    this.onCloseDialog();
                    this.$notify({
                      title: "Амжилттай.",
                      message: res.message,
                      type: "success"
                    });
                  } else {
                    this.$notify({
                      title: "Анхааруулга.",
                      message: "Хаягийг засварлах үйлдэл амжилтгүй боллоо...",
                      type: "warning"
                    });
                  }
                });
            } else {
              this.$notify({
                title: "Амжилттай.",
                message:
                  data.data.data.message + "Хаягийг амжилттай засварлалаа.",
                type: "success"
              });
              this.onCloseDialog();
            }
          } else {
            this.$notify({
              title: "Анхааруулга.",
              message: "Хаягийг засварлах үйлдэл амжилтгүй боллоо.",
              type: "warning"
            });
          }
        });
    },
    updatePath(path) {
      if (path != undefined) {
        this.newApart.lng = path.latLng.lng();
        this.newApart.lat = path.latLng.lat();
        this.apart.lat = path.latLng.lat();
        this.apart.lng = path.latLng.lng();
        this.points.lat = path.latLng.lat();
        this.points.lng = path.latLng.lng();
        services
          .getAddressNearby(this.newApart.lat, this.newApart.lng)
          .then(data => {
            this.nearbyAddress = data;
          });
      }
    },
    async changeDisrict1() {
      await services
        .getHoroos(this.oldAddressData.city, this.oldAddressData.district)
        .then(response => {
          this.horoos = response.data.data;
        });
    },
    onClickNearbyAddress(data) {
      this.oldAddressData.bairname = data.bairname;
      this.oldAddressData.bairnote = data.bairnote;
      this.oldAddressData.city = data.city;
      this.oldAddressData.district = data.district;
      this.oldAddressData.full_address = data.full_address;
      this.oldAddressData.latitude = data.latitude;
      this.oldAddressData.longitude = data.longitude;
      this.oldAddressData.podcode = data.podcode;
      this.oldAddressData.podtoktok = data.podtoktok;
      this.oldAddressData.is_active = data.is_active;
      this.$forceUpdate();
    },
    onCloseDialog() {
      this.closeFunction();
    }
  }
};
</script>
<style scoped>
.home {
  min-height: 1000px;
}
</style>
