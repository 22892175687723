<template>
  <div class="home">
    <el-row style="margin-bottom: 25px">
      <el-col :span="4">
        <el-select
          v-model="city"
          placeholder="Хотоо сонгоно уу?"
          @change="changeCity"
          size="mini"
        >
          <el-option
            v-for="(city, indexCity) in cities"
            :key="indexCity"
            :label="city.value"
            :value="city.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="district"
          placeholder="Дүүргээ сонгоно уу?"
          @change="changeDisrict"
          size="mini"
        >
          <el-option
            v-for="(district, indexDistrict) in districts"
            :key="indexDistrict"
            :label="district.value + '(' + district.count + ')'"
            :value="district.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="horoo"
          placeholder="Хороогоо сонгоно уу?"
          size="mini"
        >
          <el-option
            v-for="horoo in horoos"
            :key="horoo.name"
            :label="horoo.name + ' (' + horoo.countOfAddress + ')'"
            :value="horoo.name"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-input
          style="width: 200px"
          placeholder="Хайх хаягаа оруулна уу?"
          size="mini"
          v-model="value"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" size="mini" @click="search">Хайх</el-button>
      </el-col>
      <el-col class="text-right">
        <el-button type="success" @click="onClickNewAddress"
          >Шинээр нэмэх</el-button
        >
      </el-col>
    </el-row>
    <b>Хайсан хаяг:</b>
    <el-row>
      <el-col :span="1">&nbsp;</el-col>
      <el-col :span="24">
        <el-table size="mini" :data="aparts" stripe style="width: 100%">
          <el-table-column prop="city" label="Хот" width="60"></el-table-column>
          <el-table-column
            prop="district"
            label="Дүүрэг"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="horoo"
            label="Хороо"
            width="100"
          ></el-table-column>
          <el-table-column prop="bairname" label="Хаяг"></el-table-column>
          <el-table-column
            prop="podcode"
            label="TOKTOK"
            width="100"
          ></el-table-column>
          <!-- <el-table-column prop="podph" label="Под PH" width="100"></el-table-column> -->
          <!-- <el-table-column prop="podkfc" label="Под KFC" width="100"></el-table-column>-->
          <el-table-column
            prop="podtoktok"
            label="Под TOKTOK"
            width="100"
          ></el-table-column>
          <el-table-column label="" width="250">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="handleEdit(scope.row)"
                >Засах</el-button
              >
              <el-button size="mini" type="info" @click="logSee(scope.row.id)"
                >Түүх харах</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          class="text-right"
          @size-change="sizeChangeHandler"
          layout="total, sizes, prev, pager, next"
          :total="pageCount"
          :page-size="size"
          :page-sizes="[10, 20, 30, 50, 100]"
          @current-change="setPage"
        >
        </el-pagination>
      </el-col>
      <el-col :span="5">&nbsp;</el-col>
    </el-row>
    <el-row :gutter="24" style="margin-top:20px; margin-bottom:20px">
      <el-col :span="10">
        <b>Сүүлд хийсэн өөрчлөлтүүд:</b>
      </el-col>
      <el-col :span="4" :offset="10">
        <el-input
          style="width: 240px"
          prefix-icon="el-icon-search"
          v-model="tableSearch"
          size="mini"
          placeholder="Хайх хаягийн нэр"
        />
      </el-col>
    </el-row>
    <el-table
      size="mini"
      :data="
        logs.filter(
          data =>
            !tableSearch ||
            data.old.bairname.toLowerCase().includes(tableSearch.toLowerCase())
        )
      "
      stripe
    >
      <el-table-column
        prop="created_at"
        label="Огноо"
        sortable
        width="150"
      ></el-table-column>
      <el-table-column
        prop="old.bairname"
        label="Хуучин хаяг"
      ></el-table-column>
      <el-table-column prop="new.bairname" label="Шинэ хаяг"></el-table-column>
      <el-table-column
        prop="action"
        label="Үйлдэл"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="user"
        label="Хэрэглэгч"
        width="150"
      ></el-table-column>
      <el-table-column label="Үйлдэл" width="150">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            @click="logSeeAfterFunction(scope.row)"
            >Түүх харах</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      class="text-right"
      @size-change="lastChangeSizeChangeHandler"
      layout="total, sizes, prev, pager, next"
      :total="lastChangePageCount"
      :page-size="lastChangeSize"
      :page-sizes="[10, 20, 30, 50, 100]"
      @current-change="lastChangeSetPage"
    >
    </el-pagination>
    <address-dialog
      :addressData="this.apart"
      :type="this.type"
      :showAddressDialog="showAddressDialog"
      :updateFunction="update"
      :closeFunction="closeAddressDialog"
      :logs="logs"
      :districts="districts"
      :cities="cities"
    ></address-dialog>
    <address-log-dialog-vue
      :logsData="logsData"
      :dialogBool="dialogBool"
      :logSizeChangeHandler="logSizeChangeHandler"
      :logSetPage="logSetPage"
      :logSize="logSize"
      :logPageCount="logPageCount"
      :close="close"
    />
    <el-dialog
      title="Түүх харах"
      :visible.sync="afterLogSeeShow"
      width="90%"
      style="margin-left:15%"
      center
    >
      <span>
        <el-table :data="afterLogData">
          <el-table-column
            prop="old.bairname"
            label="Хуучин хаяг"
          ></el-table-column>
          <el-table-column
            prop="new.bairname"
            label="Шинэ хаяг"
          ></el-table-column>
          <el-table-column
            prop="old.bairnote"
            label="Хуучин албан бус хаяг"
          ></el-table-column>
          <el-table-column
            prop="new.bairnote"
            label="Шинэ албан бус хаяг"
          ></el-table-column>
          <el-table-column
            prop="old.city"
            label="Хуучин хот"
            width="68"
          ></el-table-column>
          <el-table-column
            prop="new.city"
            label="Шинэ хот"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="old.district"
            label="Хуучин дүүрэг"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="new.district"
            label="Шинэ дүүрэг"
            width="65"
          ></el-table-column>
          <el-table-column
            prop="old.horoo"
            label="Хуучин хороо"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="new.horoo"
            label="Шинэ хороо"
            width="100"
          ></el-table-column>
        </el-table>
        <el-pagination
          background
          class="text-right"
          @size-change="logSizeChange"
          layout="total, sizes, prev, pager, next"
          :total="logsTotal"
          :page-size="logsSize"
          :page-sizes="[10, 20, 30, 50, 100]"
          @current-change="logsSetPage"
        >
        </el-pagination>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="afterLogSeeShow = false" type="danger"
          >Хаах</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from "../helpers/services.js";
import { getUserName } from "../utils/auth";
import AddressDialog from "./AddressDialog";
import AddressLogDialogVue from "./AddressLogDialog.vue";
export default {
  name: "ListAddress",
  components: {
    AddressDialog,
    AddressLogDialogVue
  },
  created() {
    getUserName().then(name => {
      this.user = name.attributes.email.split("@")[0];
    });
    this.getCities();
    this.getDistrict();
    this.allLog();
  },
  data() {
    return {
      cities: [],
      city: "УБ",
      logs: [],
      logsData: [],
      type: false,
      dialogBool: false,
      afterLogSeeShow: false,
      afterLogData: [],
      afterLogDataNew: [],
      user: "",
      dialogFormVisible: false,
      dialogFormVisible1: false,
      districts: [],
      district: "",
      nearbyAddress: [],
      horoos: [],
      horoo: "",
      aparts: [],
      apart: {},
      newApart: {},
      value: "",
      showAddressDialog: false,
      tableSearch: "",
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 2,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      points: { lat: 47.9174, lng: 106.9137 },
      size: 10,
      pageCount: 0,
      from: 0,
      lastChangeSize: 10,
      lastChangePageCount: 0,
      lastChangeFrom: 0,
      logSize: 10,
      logPageCount: 0,
      logFrom: 0,
      logId: "",
      is_active: null,
      logsFrom: 0,
      logsSize: 10,
      logsTotal: 0,
      logData: []
    };
  },
  methods: {
    allLog() {
      services
        .getAddressLogs(this.lastChangeSize, this.lastChangeFrom)
        .then(res => {
          this.logs = res.data.data;
          this.lastChangePageCount = res.data.total;
        });
    },
    getCities() {
      services.getCities().then(response => {
        this.cities = response.data.data;
      });
    },
    getDistrict() {
      services.getDistricts(this.city).then(response => {
        this.districts = response.data.data;
      });
    },
    logSizeChange(size) {
      this.logsSize = size;
      this.logSeeAfterFunction(this.logData);
    },
    logSeeAfterFunction(data) {
      this.logData = data;
      services
        .getOneLog(this.logsSize, this.logsFrom, data._id)
        .then(logRes => {
          this.afterLogSeeShow = true;
          this.afterLogData = logRes.data.data.data;
          this.logsTotal = logRes.data.data.total;
        })
        .catch(er => {
          this.$notify({
            title: "Анхааруулга",
            message: "Алдаа түүх харах: " + er.message,
            type: "warning"
          });
        });
    },
    logSee(id) {
      this.logId = id;
      services
        .getOneLog(id)
        .then(logRes => {
          this.dialogBool = true;
          this.logsData = logRes.data;
          this.logPageCount = logRes.total;
        })
        .catch(er => {
          this.$notify({
            title: "Анхааруулга",
            message: "Алдаа түүх харах: " + er.message,
            type: "warning"
          });
        });
    },
    create() {
      this.newApart.user = this.user;
      services.create(this.newApart).then(data => {
        if (data.result === "created") {
          this.$notify({
            title: "Амжилттай.",
            message: "Хаягийг амжилттай нэмлээ.",
            type: "success"
          });
        } else {
          this.$notify({
            title: "Анхааруулга.",
            message: "Хаягийг нэмэх үйлдэл амжилтгүй боллоо.",
            type: "warning"
          });
        }
        this.dialogFormVisible1 = false;
      });
    },
    update() {
      this.apart.user = this.user;
      services.update(this.apart).then(data => {
        if (data.result === "updated") {
          this.$notify({
            title: "Амжилттай.",
            message: "Хаягийг амжилттай засварлалаа.",
            type: "success"
          });
        } else {
          this.$notify({
            title: "Анхааруулга.",
            message: "Хаягийг засварлах үйлдэл амжилтгүй боллоо.",
            type: "warning"
          });
        }
        this.dialogFormVisible = false;
      });
    },
    handleEdit(data) {
      this.type = false;
      this.apart = data;
      this.showAddressDialog = true;
    },
    search() {
      services
        .getAllAddress(this.size, this.from, this.value, "", this.district)
        .then(response => {
          this.aparts = response.data.data.data;
          this.pageCount = response.data.data.total_page;
        });
    },
    changeDisrict() {
      services.getHoroos(this.city, this.district).then(data => {
        this.horoos = data.data.data;
      });
    },
    changeCity() {
      this.getDistrict();
    },
    handleSelect() {
      this.aparts.forEach(element => {
        if (element.value === this.value) {
          this.apart = element;
        }
      });
    },
    onClickNewAddress() {
      this.type = true;
      this.showAddressDialog = true;
    },
    closeAddressDialog() {
      this.search();
      this.showAddressDialog = false;
    },
    sizeChangeHandler(size) {
      this.size = size;
      this.search();
    },
    setPage(page) {
      this.from = (page - 1) * this.size;
      this.search();
    },
    lastChangeSizeChangeHandler(size) {
      this.lastChangeSize = size;
      this.allLog();
    },
    lastChangeSetPage(page) {
      this.lastChangeFrom = (page - 1) * this.lastChangeSize;
      this.allLog();
    },
    logSizeChangeHandler(size) {
      this.logSize = size;
      this.logSee(this.logId);
    },
    logsSetPage(page) {
      this.logsFrom = (page - 1) * this.logsSize;
      this.logSeeAfterFunction(this.logData);
    },
    logSetPage(page) {
      this.logFrom = (page - 1) * this.logSize;
      this.logSee(this.logId);
    },
    close() {
      this.dialogBool = false;
    }
  }
};
</script>
<style scoped>
.home {
  min-height: 1000px;
}
</style>
